import React from "react";
import { useTranslation } from 'react-i18next';

const Cooperation = () => {

    const { t } = useTranslation();

    return (
        <h2>Coming Soon...</h2>
    );
};

export default Cooperation;